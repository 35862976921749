import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import Home from "./Home"
import Login from "./Login"
import Register from "./Register"
import Protected from "./Protected"
import {getUser, getToken, setUserSession, resetUserSession} from "./service/AuthService"
import React, { useEffect, useState } from "react";
import axios from 'axios'

const verifyUrl = 'https://2p3j1n17x9.execute-api.us-east-1.amazonaws.com/dev/verify';
const apiKey = 'NIuewCf2gV1EEH67RBqbF2CahSnCpOOg3ARWWA2Q';

function App() {

  const [isAuthenticating, setAuthenticating] = useState(true);
  useEffect( ()=> {
    const token = getToken();
    if( token === 'undefined' || token === undefined || token === null || !token) {
      return;
    }
    const requestConfig = {
      headers: {
          'x-api-key':apiKey
      }
  }

  const requestBody = {
      user: getUser(),
      token: JSON.parse(token)
  }
  console.log(requestBody);
  axios.post(verifyUrl, requestBody, requestConfig).then( response => {
      setUserSession(response.data.user, response.data.token);
      setAuthenticating(false);
  }).catch( error => {
      resetUserSession();
      setAuthenticating(false);
  })

  },[]);

  const token = getToken();
  if (isAuthenticating && token) {
    return <div className ="content">Autheticating ...</div>
  }
  
  return (
    <div className="App">
    <BrowserRouter>
      <div className="header">
        <NavLink activeclassname="active" to="/">
          Home
        </NavLink>
        <NavLink activeclassname="active" to="/register">
          Register
        </NavLink>
        <NavLink activeclassname="active" to="/login">
          Login
        </NavLink>
        <NavLink activeclassname="active" to="/protected">
          Protected
        </NavLink>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/protected" element={<Protected />} />
        </Routes>
      </div>
    </BrowserRouter>
  </div>
  );
}

export default App;
